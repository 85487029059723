/* eslint-disable no-warning-comments */
// @ts-check

// /**
//  *
//  * @typedef {{quality?: number}} Filters
//  * @typedef {{sm?: number, md?: number, lg?: number, unit?: 'px' | 'vw', exclude?: string | string[]}} Sizes
//  *
//  * @typedef Image
//  * @property {string} [props.src]
//  * @property {string | null} [props.filename]
//  * @property {string} [props.alt]
//  * @property {'lazy' | 'eager'} [props.loading]
//  * @property {Sizes | string} [props.sizes]
//  * @property {Filters} [props.filters]
//  * @property {boolean} [props.isFullWidth]
//  * @property {string} [props.width]
//  * @property {string} [props.height]
//  * @property {string} [props.ratio]
//  * @property {boolean} [props.fitIn]
//  * @property {string} [props.className]
//  */

import classNames from 'classnames'
import React, {forwardRef} from 'react'

type Filters = {
  quality?: number
}
type Sizes = {
  sm?: number
  md?: number
  lg?: number
  unit?: 'px' | 'vw'
  exclude?: string | string[]
}
type TImage = {
  filename?: string | undefined
  alt: string | undefined
  sizes?: Sizes
  filters?: Filters
  isFullWidth?: boolean
  loading?: 'eager' | 'lazy' | undefined
  ratio?: string
  fitIn?: boolean
  className?: string
  width?: string
  height?: string
  style?: {visibility?: string}
  fixedWidth?: number
}
const breakpoints = {
  md: 768,
  lg: 1280,
  max: 1920,
}
const originalSrcRegex = new RegExp(/\/\/a.storyblok.com/)

// /**
//  *
//  * @param {string} src
//  * @returns {{width: number; height: number} | undefined}
//  */
function getDimensions(src: string) {
  if (isStoryblokImage(src)) {
    const match = src.match(/(\d+x\d+)/g)
    if (match && match[0]) {
      const [w, h] = match[0].split('x')
      return {width: parseInt(w), height: parseInt(h)}
    }
  }

  return undefined
}

// /**
//  *
//  * @param {string} src
//  * @returns {boolean}
//  */
function isStoryblokImage(src: string) {
  return !!src && originalSrcRegex.test(src)
}

// /**
//  *
//  * @param {string} src
//  * @returns {boolean}
//  */
function isSvg(src: string) {
  return !!src && /\.svg$/.test(src)
}

// /**
//  *
//  * @param {string} src
//  * @returns {boolean}
//  */
function isJpg(src: string) {
  const regex = /\.(jpeg|jpg)$/i
  return !!src && regex.test(src)
}

// /**
//  *
//  * @param {string} src
//  * @returns {string}
//  */
function withCustomCDN(src: string) {
  return src.replace('a.storyblok.com', 'pelostudio-storyblok-assets.b-cdn.net')
}

// /**
//  *
//  * @param {string} src
//  * @param {Filters} filters
//  * @returns {string}
//  */
function getFilters(src: string, filters: Filters) {
  const options = {
    baseUrl: '/filters',
    quality: `quality(${filters?.quality || 70})`,
    format: isJpg(src) ? 'format(webp)' : undefined,
  }

  return Object.values(options)
    .filter((v) => !!v)
    .join(':')
}

// /**
//  *
//  * @param {number} width
//  * @param {string} ratio
//  * @returns {number | undefined}
//  */
function getHeight(width: number | undefined, ratio: string) {
  if (width && ratio) {
    const [w, h] = ratio.split(':').map((v) => parseInt(v))
    return Math.round((width * h) / w)
  }

  return 0
}

// /**
//  *
//  * @param {string} src
//  * @param {number} width
//  * @param {Filters} filters
//  * @param {string} ratio
//  * @param {boolean} fitIn
//  * @returns {string}
//  */
function getSrc(
  src: string,
  width: number | undefined,
  filters: Filters,
  ratio: string,
  fitIn: boolean
) {
  if (!src) return ''

  if (!isStoryblokImage(src)) {
    return src
  } else if (isSvg(src)) {
    return withCustomCDN(src)
  } else {
    const height = getHeight(width, ratio) || 0
    const options = {
      fitIn: fitIn ? '/fit-in' : undefined,
      width: width ? `/${width}x${height}` : '',
      smart: '/smart',
      filters: getFilters(src, filters),
    }
    const fullUrl = [withCustomCDN(src), '/m', ...Object.values(options)]
      .filter((v) => !!v)
      .join('')
    return fullUrl
  }
}

// /**
//  *
//  * @param {string} src
//  * @param {Filters} filters
//  * @param {string} ratio
//  * @param {boolean} fitIn
//  * @returns {string}
//  */
function getSet(
  src: string,
  filters: Filters,
  ratio: string,
  fitIn: boolean,
  isFixed?: boolean,
  fixedWidth?: number
) {
  if (
    !src ||
    !isStoryblokImage(src) ||
    isSvg(src) ||
    /(?:https?|ftp):\/\/[\S]*\.(?:webp)(?:\?\S+=\S*(?:&\S+=\S*)*)?/gi.test(src)
  )
    return undefined

  let widths = [256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]

  const dimensions: {width: number; height: number} | undefined = getDimensions(src)

  if (dimensions) {
    if (isFixed && fixedWidth) {
      widths = widths
        .concat([fixedWidth, fixedWidth * 2, fixedWidth * 3, fixedWidth * 4])
        .sort((a, b) => a - b)
    } else {
      widths = widths
        .concat([dimensions.width])
        .sort((a, b) => a - b)
        .filter((w) => w <= dimensions.width)
    }
  }

  let set = widths.map((width) => `${getSrc(src, width, filters, ratio, fitIn)} ${width}w`)

  const retinaWidths = (f: number, w: number) => {
    if (f * 4 <= w) {
      return f * 3
    } else if (f * 3 <= w) {
      return f * 3
    } else if (f * 2 <= w) {
      return f * 2
    } else {
      return f
    }
  }

  if (isFixed && fixedWidth) {
    set = widths.map((width) => {
      return `${getSrc(src, retinaWidths(fixedWidth, width), filters, ratio, fitIn)} ${width}w`
    })
  }

  return set.join()
}

// /**
//  *
//  * @param {string} src
//  * @param {Sizes} options
//  * @returns {string}
//  */
function getSizes(src: string, options: Sizes) {
  if (!options || !src || isSvg(src)) return undefined

  if (typeof options === 'string') {
    return options
  }

  const base = 100
  const {sm, md, lg, unit = 'vw'} = options

  const definitions = {
    max: breakpoints.max * ((lg || md || sm || base) / 100),
    lg: lg || md || sm || base,
    md: md || sm || base,
    sm: sm || base,
  }

  const sizes = [
    unit === 'px' ? undefined : `(min-width: ${breakpoints.max}px) ${definitions.max}px`,
    `(min-width: ${breakpoints.lg}px) ${definitions.lg}${unit}`,
    `(min-width: ${breakpoints.md}px) ${definitions.md}${unit}`,
    `${definitions.sm}${unit}`,
  ]

  return sizes.filter(Boolean).join(', ')
}

// /**
//  *
//  * @param {...Image} props
//  * @returns {JSX.Element | null}
//  */
const Image = (
  {
    filename,
    sizes = {sm: 100},
    filters = {quality: 100},
    isFullWidth = false,
    alt = '',
    loading = 'lazy',
    width,
    height,
    ratio = '',
    fitIn = false,
    className,
    style,
    fixedWidth,
  }: TImage,
  ref?: React.LegacyRef<HTMLImageElement>
): JSX.Element | null => {
  const src = filename

  if (src) {
    const newSrc = getSrc(src, undefined, filters, ratio, fitIn)
    let srcSet = getSet(src, filters, ratio, fitIn)
    if (fixedWidth) {
      srcSet = getSet(src, filters, ratio, fitIn, true, fixedWidth)
    }

    const newSizes = isFullWidth ? '100vw' : getSizes(src, sizes)
    const dimensions = getDimensions(src)

    if (dimensions && ratio) {
      dimensions.height = getHeight(dimensions.width, ratio)
    }

    return (
      <img
        src={newSrc}
        srcSet={srcSet}
        sizes={newSizes}
        alt={alt || ''}
        loading={loading}
        width={width || dimensions?.width}
        height={height || dimensions?.height}
        className={classNames(
          {'object-cover': !!ratio && !fitIn, 'object-contain': !!ratio && fitIn},
          className
        )}
        {...style}
        ref={ref}
      />
    )
  }

  return null
}

export default forwardRef(Image)
