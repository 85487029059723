import classNames from 'classnames'
import {useEffect, useState} from 'react'

import {CloseS, LogoNavy, LogoNavyInverted, MenuS} from '@/assets/specify'
import {componentTypes} from '@/common/enums'
import {TNavigationV2} from '@/common/types'
import Button from '@/components/Button'
import NavigationItem from '@/components/NavigationItem'

import LanguageSwitcher from '../LanguageSwitcher'
import Link from '../Link'
import styles from './NavigationV2.module.scss'

const NavigationV2 = ({
  items = [],
  homeLink,
  btnLink,
  btnLabel,
  lightNavigation,
  languages,
}: TNavigationV2): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : ''
  }, [isOpen])

  return (
    <section
      className={classNames(
        styles.section,
        {[styles.isOpen]: isOpen},
        {[styles.isLight]: lightNavigation}
      )}
    >
      <div className={styles.navwrapper}>
        <nav className={classNames(styles.navigation)}>
          <Link link={homeLink}>
            {lightNavigation ? (
              <LogoNavyInverted title='Home' style={{height: '50px'}} />
            ) : (
              <LogoNavy title='Home' style={{height: '50px'}} />
            )}
          </Link>

          <div className={styles.items}>
            {items.map((item, i) => {
              if (item.component === componentTypes.NAVIGATION_ITEM) {
                return (
                  <>
                    <NavigationItem key={item._uid} {...item} className={styles.item} />{' '}
                    {i === 1 && <hr className={styles.itemdivider} />}
                  </>
                )
              }
              return null
            })}
          </div>

          {btnLink && btnLabel && (
            <div className={styles.cta}>
              <Button link={btnLink} label={btnLabel} isSecondary={lightNavigation} />
            </div>
          )}

          <hr className={styles.divider} />

          {languages && (
            <div className={styles.desktopLanguages}>
              <LanguageSwitcher languages={languages} isLight={lightNavigation} />
            </div>
          )}

          <div className={styles.burgerbuttons}>
            <button
              aria-hidden='true'
              tabIndex={-1}
              className={styles.close}
              onClick={() => setIsOpen(false)}
            >
              <CloseS />
            </button>
            <button
              aria-hidden='true'
              tabIndex={-1}
              className={styles.burger}
              onClick={() => setIsOpen(true)}
            >
              <MenuS />
            </button>
          </div>
        </nav>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.mobileitems} aria-hidden='true'>
          {items.map((item) => {
            if (item.component === componentTypes.NAVIGATION_ITEM) {
              return (
                <div key={item._uid} className={styles.mobileitemwrapper}>
                  <NavigationItem {...item} className={styles.mobileitem} />
                </div>
              )
            }
            return null
          })}
          {languages && (
            <div className={styles.mobilelanguages}>
              <LanguageSwitcher languages={languages} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default NavigationV2
