import classNames from 'classnames'
import {Fragment} from 'react'

import {LogoNavy, LogoNavyInverted} from '@/assets/specify'
import {componentTypes} from '@/common/enums'
import {TNavigation} from '@/common/types'
import Button from '@/components/Button'
import PopoverNavigation from '@/components/NavigationDesktop/PopoverNavigation'
import NavigationItem from '@/components/NavigationItem'

import LanguageSwitcher from '../LanguageSwitcher'
import Link from '../Link'
import styles from './NavigationDesktop.module.scss'

const DesktopNavigation = ({
  items1 = [],
  items2 = [],
  homeLink,
  btnLink,
  btnLabel,
  lightNavigation,
  languages,
}: TNavigation): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <nav className={classNames(styles.navigation, {[styles.isLight]: lightNavigation})}>
        <Link link={homeLink}>
          {lightNavigation ? (
            <LogoNavyInverted title='Home' style={{height: '50px'}} />
          ) : (
            <LogoNavy title='Home' style={{height: '50px'}} />
          )}
        </Link>

        <div className={styles.items}>
          {[items1, items2].map((items, index) => (
            <div key={index} className={styles.category}>
              {items.map((item, index) => {
                if (item.component === componentTypes.NAVIGATION_ITEM) {
                  return <NavigationItem key={item._uid} {...item} className={styles.item} />
                } else if (item.component === componentTypes.NAVIGATION_ITEM_RICH) {
                  return <PopoverNavigation key={item._uid} item={item} index={index} />
                }
                return null
              })}
              {index === 0 && items2.length > 0 ? <div className={styles.categorySplit} /> : null}
            </div>
          ))}
        </div>

        {btnLink && btnLabel && (
          <div>
            <Button link={btnLink} label={btnLabel} isSecondary={lightNavigation} />
          </div>
        )}

        {languages && (
          <>
            <hr className={styles.divider} />
            <LanguageSwitcher languages={languages} isLight={lightNavigation} />
          </>
        )}
      </nav>
    </div>
  )
}

export default DesktopNavigation
