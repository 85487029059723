// import channels from '@/assets/channels/channels.json'
import channels from 'public/channels/channels.json'
import {useEffect, useRef, useState} from 'react'

import {TChannelsV3ActiveFilters, TChannelsV3Channel} from '@/common/types'
import {TChannelsV3} from '@/common/types'

import ButtonSecondary from '../ButtonSecondary'
import Cards from './Cards'
import styles from './ChannelsV3.module.scss'
import Empty from './Empty/'
import Filters from './Filters'

const ChannelsV3 = ({
  filtersMobileOpenButton,
  contentRegularHeading,
  filtersMobileSearchButton,
  filtersCategoriesHeading,
  filtersIndustriesHeading,
  filtersCountriesHeading,
  filtersSearchHeading,
  noResultsHeading,
  noResultsDescription,
  noResultsButtons,
}: TChannelsV3): JSX.Element => {
  // const channelsList = channels?.slice(0, 100)
  const channelsList = channels

  const [showFilters, setShowFilters] = useState(false)
  const filtersOpenButtonRef = useRef<HTMLDivElement>(null)
  const [filters, setFilters] = useState<TChannelsV3ActiveFilters>()
  const [filteredChannels, setFilteredChannels] = useState<TChannelsV3Channel[]>()

  useEffect(() => {
    const items = channelsList || []
    const sortedChannels = items.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      } else if (b.name > a.name) {
        return 1
      } else {
        return 0
      }
    })

    let filtered_channels = sortedChannels

    if (
      filters !== undefined &&
      (filters.activeCategories.length > 0 ||
        filters.activeIndustries.length > 0 ||
        filters.activeCountries.length > 0)
    ) {
      filtered_channels = sortedChannels.filter(
        (channel) =>
          filters.activeCategories.includes(channel.category) ||
          filters.activeIndustries.includes(channel.type) ||
          filters.activeCountries.some((r) => channel.langs.includes(r))
      )
    }

    if (filters !== undefined && filters.search) {
      filtered_channels = filtered_channels.filter((channel) =>
        filters.search ? channel.name.toLowerCase().startsWith(filters.search.toLowerCase()) : true
      )
    }

    setFilteredChannels(filtered_channels)
  }, [filters])

  return (
    <section className={styles.section}>
      <div className={styles.filters}>
        <div className={styles.filtersButton} ref={filtersOpenButtonRef}>
          <ButtonSecondary label={filtersMobileOpenButton} onClick={() => setShowFilters(true)} />
        </div>
        <Filters
          searchHeading={filtersSearchHeading}
          onChange={setFilters}
          mobileSearchButton={filtersMobileSearchButton}
          categoriesHeading={filtersCategoriesHeading}
          industriesHeading={filtersIndustriesHeading}
          countriesHeading={filtersCountriesHeading}
          isVisible={showFilters}
          onClose={() => setShowFilters(false)}
          resultsLength={filteredChannels ? filteredChannels.length : 0}
        />
      </div>

      <div className={styles.content}>
        {filteredChannels && filteredChannels.length > 0 && (
          <div className={styles.cards}>
            <Cards channels={filteredChannels} heading={contentRegularHeading} />
          </div>
        )}
        {filteredChannels && filteredChannels.length === 0 && (
          <div className={styles.cards}>
            <Empty
              heading={noResultsHeading}
              description={noResultsDescription}
              buttons={noResultsButtons}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default ChannelsV3
