// import classNames from 'classnames'

// import {ChevronRightXs} from '@/assets/specify'
import Image from 'next/image'

import {TChannelsV3Card} from '@/common/types'

// import ButtonTertiary from '@/components/ButtonTertiary'
// import StoryblokImage from '@/components/StoryblokImage'
import styles from './ChannelsV3Card.module.scss'

const ChannelsV3Card = ({logo = '', heading}: TChannelsV3Card): JSX.Element => (
  <div className={styles.card} aria-hidden='true'>
    <div className={styles.background}>
      <div className={styles.logocontainer}>
        {logo && logo !== '' && (
          <Image src={logo} width={200} height={100} alt='logo alt' className={styles.logo} />
        )}
      </div>
    </div>
    {heading && (
      <div className={styles.header}>
        <h4 className={styles.heading}>{heading}</h4>
      </div>
    )}
  </div>
)

export default ChannelsV3Card
