/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames'
import lookup from 'country-code-lookup'
import categories from 'public/channels/channelscategories.json'
import countries from 'public/channels/channelslangs.json'
import industries from 'public/channels/channelstypes.json'
import {useEffect, useRef, useState} from 'react'

import {CloseS} from '@/assets/specify'
// import {translationNamespaces} from '@/common/enums'
import {TChannelsV3Filters} from '@/common/types'
import Button from '@/components/Button'

// import {useTranslations} from '@/hooks/useTranslations'
import styles from './ChannelsV2Filters.module.scss'

function withCount(label?: string, count?: number): string | undefined {
  if (label && typeof count === 'number') {
    return label.replace('__', count.toString())
  }
}

const ChannelsV3Filters = ({
  searchHeading,
  searchPlaceholder,
  mobileSearchButton,
  categoriesHeading,
  industriesHeading,
  countriesHeading,
  isVisible,
  onChange = () => undefined,
  onClose = () => undefined,
  resultsLength,
}: TChannelsV3Filters): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)

  // const {t, getName} = useTranslations()
  const [search, setSearch] = useState('')
  const [activeCategories, setActiveCategories] = useState<string[]>([])
  const [activeIndustries, setActiveIndustries] = useState<string[]>([])
  const [activeCountries, setActiveCountries] = useState<string[]>([])

  function handleClose() {
    if (isVisible && containerRef.current) {
      containerRef.current.scrollTo(0, 0)
    }
    onClose()
  }

  useEffect(() => {
    onChange({
      activeCategories: activeCategories,
      activeIndustries: activeIndustries,
      activeCountries: activeCountries,
      search: search,
    })
  }, [activeCategories, activeIndustries, activeCountries, search])

  return (
    <aside
      className={classNames(styles.container, {[styles.isVisible]: isVisible})}
      ref={containerRef}
    >
      <div className={styles.header}>
        {searchHeading && <p className={styles.heading}>{searchHeading}</p>}
        <CloseS className={styles.closeIcon} onClick={handleClose} />
      </div>

      <div className={styles.search}>
        <input
          className={styles.input}
          placeholder={searchPlaceholder}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {categoriesHeading && <p className={styles.heading}>{categoriesHeading}</p>}
      <div className={styles.buttons}>
        {categories.map((category) => {
          const isActive = activeCategories.find((c) => c === category)
          if (category === '') return null
          return (
            <button
              key={category}
              className={classNames(styles.button, {
                [styles.isActive]: isActive,
              })}
              onClick={() =>
                setActiveCategories((prev) =>
                  isActive ? prev.filter((c) => c !== category) : prev.concat(category)
                )
              }
            >
              {category}
              {/* {t(translationNamespaces.CHANNELS_V2_CATEGORIES, category)} */}
            </button>
          )
        })}
      </div>

      {industriesHeading && <p className={styles.heading}>{industriesHeading}</p>}
      <div className={styles.buttons}>
        {industries.map((industry) => {
          const isActive = activeIndustries.find((i) => i === industry)

          return (
            <button
              key={industry}
              className={classNames(styles.button, {
                [styles.isActive]: isActive,
              })}
              onClick={() =>
                setActiveIndustries((prev) =>
                  isActive ? prev.filter((i) => i !== industry) : prev.concat(industry)
                )
              }
            >
              {industry}
              {/* {t(translationNamespaces.CHANNELS_V2_INDUSTRIES, industry)} */}
            </button>
          )
        })}
      </div>

      {countriesHeading && <p className={styles.heading}>{countriesHeading}</p>}
      <div className={styles.buttons}>
        {countries.map((country) => {
          const isActive = activeCountries.find((c) => c === country)
          let countrycode = lookup.byCountry(country)?.iso2.toLowerCase()
          if (country === 'Czech Republic') {
            countrycode = 'cz'
          } else if (country === 'Iran, Islamic Republic Of') {
            countrycode = 'ir'
          } else if (country === 'Russian Federation') {
            countrycode = 'ru'
          } else if (country === 'Viet Nam') {
            countrycode = 'vn'
          } else if (country === 'Korea, Republic Of') {
            countrycode = 'kr'
          } else if (country === 'Taiwan, Province Of China') {
            countrycode = 'tw'
          }

          if (country === '') return null

          return (
            <button
              key={country}
              className={classNames(styles.button, {
                [styles.isActive]: isActive,
              })}
              onClick={() =>
                setActiveCountries((prev) =>
                  isActive ? prev.filter((c) => c !== country) : prev.concat(country)
                )
              }
            >
              <span className={`fi fi-${countrycode}`} /> {country}
              {/* {t(translationNamespaces.CHANNELS_V2_COUNTRIES, country)} */}
            </button>
          )
        })}
      </div>

      <div className={styles.closeButton}>
        <Button label={withCount(mobileSearchButton, resultsLength)} onClick={onClose} />
      </div>
    </aside>
  )
}

export default ChannelsV3Filters
