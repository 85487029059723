import {TChannelsV3Cards} from '@/common/types'

import Card from '../Card'
import styles from './ChannelsV3Cards.module.scss'

const ChannelsV3 = ({heading, channels}: TChannelsV3Cards): JSX.Element => {
  return (
    <>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      <div className={styles.cards}>
        {channels &&
          channels.length > 0 &&
          channels.map((props) => (
            <Card key={props.uuid} heading={props.name} logo={props.image} />
          ))}
      </div>
    </>
  )
}

export default ChannelsV3
